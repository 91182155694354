<template>

  <div>

  
  <v-app-bar
    :color="scrollY > 0 ? 'primary' : 'transparent'"
    :elevate-on-scroll="scrollY > 0"
    height="90"
    :elevation="scrollY > 0 ? 5 : 0"
    dark
    app
  >
    <div href="/" style="height: 70px; width: 70px; border:solid 2px white; border-radius:50%"  >
      <v-img @click="navegar()" href="/" height="100%" width="100%" src="../assets/logo.png"></v-img>
    </div>
    <v-spacer></v-spacer>

    <v-btn href="/" text class="d-none d-md-flex">
      <span class="mr-2 font-weight-black text-subtitle-1">Inicio</span>
    </v-btn>
    <v-btn href="/conductor" text class="d-none d-md-flex">
      <span class="mr-2 font-weight-black text-subtitle-1">Conductor</span>
    </v-btn>
    <v-btn href="/nosotros" text class="d-none d-md-flex">
      <span class="mr-2 font-weight-black text-subtitle-1">Nosotros</span>
    </v-btn>

    <v-spacer></v-spacer>
    <div style="height: 35px; width: 125px;" class="mr-2">
        <v-img height="100%" width="100%" src="../assets/img/playlogo.png"></v-img>
      </div>
     
      <v-app-bar-nav-icon @click="toggleDrawer" class="d-sm-none"></v-app-bar-nav-icon>
  </v-app-bar>

  <v-navigation-drawer right v-model="drawer" app temporary >
    <v-list class="text-center">
      <v-list-item link to="/">
        <v-list-item-title>INICIO</v-list-item-title>
      </v-list-item>
   
      <v-list-item link to="/conductor">
        <v-list-item-title>CONDUCTOR</v-list-item-title>
      </v-list-item>
      <v-list-item link to="/nosotros">
        <v-list-item-title>NOSOTROS</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</div>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0, // Almacena el desplazamiento en Y
      drawer: false
    };
  },
  mounted() {
    // Escucha el evento de scroll en la ventana
    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    // Limpia el evento de scroll al desmontar el componente
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      // Actualiza el desplazamiento en Y cada vez que se hace scroll
      this.scrollY = window.scrollY;
    },
    toggleDrawer() {
      this.drawer = !this.drawer; // Cambia el estado de la visibilidad del drawer
    },
    navegar(){
      this.$router.push('/'); // Redirige a /login
  }
  },


};
</script>

<style scoped>
.logo {
  background: orangered;
  width: 70px;
  height: 30px;
}
</style>
