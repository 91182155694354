<template>
  <div class="image-container py-8">
    <v-container>
      <v-row>
        <v-col cols="12" md="12" class="px-md-10">
          <h1
            class="mt-md-6 text-center text-h4 text-md-h3 font-weight-black text-md-h3"
          >
            {{ title }}
          </h1>
        </v-col>
        <v-col cols="12" md="12">
          <v-row class="my-5">
            <v-col
              class="text-center"
              cols="12"
              :md="item.tamanio_columna"
              v-for="(item, i) of beneficios"
              :key="i"
            ><div :class="modulo =='usuario' ? 'text-with-background px-3 mb-5' : ' px-3 mb-5 text-center'">
         
              <p class="font-weight-bold text-md-h4 text-h5">{{ item.titulo }}</p>
            </div>
              <v-img class="rounded-pill" :src="item.url"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { userService } from "@/service/controller";
export default {
  name: "Beneficios",
  props: {
    title: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    beneficios: [],
  }),

  created() {
    try {
      userService.getBeneficios(this.modulo).then((re) => {
        const data = re.data;
        this.beneficios = data.result;
       
      });
    } catch (error) {
      console.error("Error .- ", error);
    }
  },
};
</script>
<style scoped>
.text-with-background {
  background-color: orangered; /* Fondo de color azul claro */
  color: white; /* Color del texto */
  border-radius: 5px; /* Bordes redondeados */
  display: inline-block; /* Ajustar el tamaño al texto */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra para destacar */

}

p{
  margin: 0px
}
</style>