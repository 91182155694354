<template>
  <div>
    <carousel :modulo="'principal'"></carousel>
    <br />
    <br />
    <viaje></viaje>
    <br />
    <br />
    <div class="servicios py-8"> 
    <v-container class="contenido ">
      <h1 class="font-weight-black text-center mb-10 text-md-h3">NUESTROS SERVICIOS</h1>
      <div class="listado-servicios">
        <v-row no-gutters justify="center">
          <v-col cols="12" md="3" order="last" v-for="(item, i) of servicios" :key="i">
            <v-card
              class="rounded-xl d-flex flex-column align-center justify-center mb-md-0 mb-8 mx-2 pt-5"
              elevation="4"
            >
              <v-img cover
                :src="item.foto"
                class="rounded-pill img-service"
                max-height="250px"
                width="450px"
              >
              </v-img>

              <p class="mt-3" v-html="formattedText(item.nombre)"></p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
    <beneficios :title="'BENEFICIOS AL USAR UN RAY'" :modulo="'usuario'"></beneficios>
    <seguridad></seguridad>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import viaje from "@/components/PedirViaje";
import beneficios from "@/components/Beneficios";
import seguridad from "@/components/Seguridad";
import { userService } from "@/service/controller";

export default {
  name: "Home",

  components: {
    Carousel,
    viaje,
    seguridad,
    beneficios
  },
  data: () => ({
    servicios: [],
  }),

  methods: {
    recibeTitulo(item) {
      return item;
    },
    getServicios() {
      try {
        userService.getServicios().then((re) => {
          const data = re.data;
          const list = data.result;
          for(let i in list){
            if(list[i].estado == 1){
              this.servicios.push(list[i]);
            }
          }
       //   this.servicios = data.result;
         // console.log(re);
        });
      } catch (error) {
        console.error("Error .- ", error);
      }
    },
    formattedText(_text) {
      const text = _text;

      // Separar la primera palabra y el resto del texto
      const [firstWord, ...restOfText] = text.split(" ");

      // Crear el texto HTML con la primera palabra resaltada y con `text-nowrap`
      return `<span style="color:white; background:orangered;" class="first-word nowrap">${firstWord}</span> ${restOfText.join(
        " "
      )}`;
    },
  },
  computed: {},
  created() {
    this.getServicios();
  },
};
</script>
<style scoped>
.row-normal {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;

  color: rgba(255, 153, 0, 0.5);
}

.img-service {
 /* border: 5px solid orangered;*/
}

.back {
  background: orangered;
  height: 35px;
}

/* Estilo para la primera palabra */
.first-word {
  color: red !important; /* Color de la primera palabra */
  font-weight: bold;
}

/* Evita que la primera palabra se divida en líneas */
.nowrap {
  white-space: nowrap;
}

/* Estilo para el resto del texto */
p {
  font-size: 24pt;
  color: black; /* Color del texto general */
  font-weight: bolder;
}

.servicios {

    background: rgb(255, 249, 246);

}
</style>
