
import api from './apiservice';

export const userService = {
  // Método para registrar un usuario
  register(userData) {
    return api.post('/user/register', userData);
  },

  // Método para registrar un usuario
  createRoute(router) {
    return api.post('/route/add-route', router);
  },

  createUserConductor(data) {
    return api.post('/web_user/registro_conductor', data);
  },

  updatePasswordConductor(data) {
    return api.post('/user/reset-password', data);
  },

  // Método para logearse
  login(userData) {
    return api.post('/user/login', userData);
  },


  enviar() {
    return api.post('/web_user/pruebas');
  },

  // Método para logearse
  resetPasswordUserReset(_email) {
    return api.post('/user/request-password-reset', { email: _email });
  },

  updatePasswordUser(data) {
    return api.post('/user/reset-password', { token: data.token, newPassword: data.newPassword });
  },

  // Método para obtener todos los usuarios
  getCarousel(modulo) {
    return api.get(`/carousel/modulo/${modulo}`);
  },

    // Método para obtener todos los usuarios
    getNosotros() {
      return api.get(`/web_user/nosotros`);
    },

    
    // Método para obtener todos los usuarios
    getServiciosActivos() {
      return api.get(`/servicios/activos`);
    },

  getServicios() {
    return api.get(`/servicios/todos`);
  },

  getBeneficios(modulo) {
    return api.get(`/web_user/beneficios/${modulo}`);
  },

  getRequisitos() {
    return api.get(`/web_user/requisitos`);
  },

  // Método para obtener todos los usuarios
  getAllUsersRoute(page) {
    return api.get('/user/getUserRoute', {
      params: {
        page: page, // Página solicitada
        limit: 10   // Tamaño de página
      }
    });
  },

  //MÉTODO PARA OBTERN ROUTES
  getAllRoutes(page) {
    return api.get('/route/all-routes', {
      params: {
        page: page,
        limit: 10
      }
    });
  },

  //MÉTODO PARA OBTERN ROUTES especifica
  getRouteId(_id) {
    return api.get('/route/idRoute', {
      params: {
        id: _id
      }
    });
  },

  getRouteIdUser(_id, page) {
    return api.get('/route/idUser', {
      params: {
        id: _id,
        page: page,
        limit: 10
      }
    });
  },


  // Método para obtener LOS DEVICES
  getAllDevices() {
    return api.get('/route/allDevices');
  },


  // Método para obtener permissos activos de usuarios
  getPermission() {
    return api.get('/user/permission');
  },

  //MÉTODO PARA OBTENER UN USUARIO EN ESPECIFICO POR ID
  getUserById(_id) {
    return api.get('/user/userById', {
      params: {
        id: _id   // Tamaño de página
      }
    });
  },


  // Método para obtener todos los usuarios
  validateDpi(valor) {
    return api.get('/user/validate-dpi', {
      params: {
        valor: valor
      }
    });
  },

  validateEmail(valor) {
    return api.get('/user/validate-email', {
      params: {
        valor: valor
      }
    });
  },

  // Método para actualizar un rol usuario
  updateRol(data) {
    return api.put(`/user/update-rol`, data);
  },

  // Método para actualizar estado de route
  updateStatusRoute(data) {
    return api.put(`/route/update-status-route`, data);
  },

  // Método para actualizar un usuario
  updateUser(data) {
    return api.put(`/user/update-user`, data);
  },

  // Método para eliminar un usuario
  deleteUser(userId) {
    return api.delete(`/user/delete-user/${userId}`);
  },


  // Método para eliminar un usuario
  deleteRoute(userId) {
    return api.delete(`/route/delete-route/${userId}`);
  },

  //###############################################################################################333
  // APARTADO PARA LLAMADAS A MENU DRAWER 

  // Método el menu
  getMenuDrawer() {
    return api.get('/menu_drawer/list');
  },

};
