import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#ff4500', // Cambia a cualquier color hexadecimal que prefieras
            secondary: '#03A9F4',
            accent: '#9C27B0',
            error: '#F44336',
          },
       
        },
      },
});


