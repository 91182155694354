<template>
  <div>
    <v-carousel
     :height="carouselHeight"
      cover
      hide-delimiters
      :show-arrows="false"
      show-arrows-on-hover
      hide-delimiter-background
    >
      <v-carousel-item
        style="position: relative"
        v-for="(item, i) in imagen"
        :key="i"
        :src="item.src"
      >
        <div
          :class="item.titulo.length > 50 ? 'cajon-titulo' : 'cajon-titulo-corto'"
          v-if="item.titulo != ''"
        >
          <div class="mensaje" v-html="item.titulo"></div>
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { userService } from "@/service/controller";
export default {
  name: "Carousel",
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    imagen: [],
    carouselHeight: '', 
  }),
  mounted() {
    this.updateCarouselHeight();
    window.addEventListener('resize', this.updateCarouselHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCarouselHeight);
  },
  methods: {
    updateCarouselHeight() {
      // Cambia la altura según el ancho de la ventana
      this.carouselHeight = window.innerWidth <= 600 ? '350' : '650';
    },
  },
  created() {
    try {
      userService.getCarousel(this.modulo).then((re) => {
        const data = re.data;
        this.imagen = data.result;
      });
    } catch (error) {
      console.error("Error .- ", error);
    }
  },
};
</script>

<style scoped>
.cajon-titulo {
  position: absolute;
  top: 39%;
  left: 5%;
  padding: 10px;
  width: 45%;
  height: auto;
  background: rgba(3, 3, 3, 0.5);
  position: absolute;
  border-radius: 10px;
}

.cajon-titulo-corto {
  position: absolute;
  top: 39%;
  left: 5%;
  padding: 10px;
  width: auto;
  height: auto;
  background: rgba(3, 3, 3, 0.5);
  position: absolute;
  border-radius: 10px;
}

.mensaje {
  font-size: 3.3rem;
  color: white;
  /* font-weight: bolder;*/
}

@media (max-width: 768px) {
  .cajon-titulo {
    position: absolute;
    top: 45%;
    padding: 10px;
    width: auto;
    left: 0 !important;
    height: auto;
    background: rgba(143, 143, 143, 0.3);
    position: absolute;
    border-radius: 0 !important;
  }

  .cajon-titulo-corto {
    position: absolute;
    text-align: center;
    top: 39%;
    left: 0%;
    padding: 10px;
    width: 100%;
    height: auto;
    background: rgba(3, 3, 3, 0.5);
    position: absolute;
    border-radius: 10px;
  }

  .mensaje {
    font-size: 2rem;
    color: white;
    font-weight: bolder;
  }
}
</style>
