<template>
  <v-app>
    <appbar ></appbar>
    <v-main class="main-content" >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import appbar from "@/components/AppBar.vue";

export default {
  name: "App",
  components: { appbar },
  data: () => ({
    //
  }),
};
</script>
<style>
.main-content {
margin-top: -100px; /* Ajusta según la altura de tu appbar */
}

/* Ajuste para pantallas móviles */
@media (max-width: 600px) {
  .main-content {
    padding-top: 56px;
  }
}
</style>
