import axios from 'axios';
import router from '@/router/index';

const api = axios.create({
  baseURL: 'https://unrayappserver.onrender.com/api',//process.env.VUE_APP_API_URL_LOCAL,   // VUE_APP_API_URL_LOCAL -- APP_API_URL

  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "*"
  }
});


/*
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Token de autenticación
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);*/



export default api;
