import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import vuetify from './plugins/vuetify'

Vue.config.productionTip = false;

router.afterEach((to) => {
  // Cambia el título de la pestaña usando el meta.title
  const defaultTitle = 'Un Ray'; // Título por defecto si no hay meta.title
  document.title = to.meta.title || defaultTitle;
});


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
